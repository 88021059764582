import type { FC } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  PhotoCamera as PhotoCameraIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import type { Theme } from '@mui/material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import routes from 'src/routes';
import { TestIDs } from 'src/testIDs';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';
import { useEventCategoriesRead } from 'src/services/state/server/Event';
import type { CaptureResult } from 'src/components/PhotoDialog/PhotoDialog';
import PhotoDialog from 'src/components/PhotoDialog/PhotoDialog';

import useStyles from './EventForm.styles';

interface EventFormProps {
  className?: string;
}

interface Values {
  category: number;
  description: string;
  pictures: CaptureResult[];
  priority: number;
  submit: string;
}

const ViewTestIDs = TestIDs.views.dut.dutEventView;

const EventForm: FC<EventFormProps> = ({ className, ...props }) => {
  const { t } = useTranslation();
  const { axiosRequestConfig: authConfig } = useUserStore();
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const theme = useTheme<Theme>();
  const { enqueueSnackbar } = useSnackbar();
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const { data: categories = [] } = useEventCategoriesRead();
  let { dutId } = useParams<{
    dutId: string;
  }>();
  dutId = dutId || '';
  const showButtonText = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const dutPath = generatePath('/dut' + routes.dut.routes?.index.path || '', {
    dutId: `${dutId}`,
  });

  const maxPhotos = 10;
  const [, ...priorityLevels] = Array(11).keys();
  const initialValues: Values = {
    category: '' as unknown as number,
    description: '',
    pictures: [],
    priority: '' as unknown as number,
    submit: '',
  };

  const validationSchema = Yup.object().shape({
    category: Yup.number().required(
      t('Views.DutEvent.DutEventForm.categoryRequired'),
    ),
    description: Yup.string()
      .max(255)
      .required(t('Views.DutEvent.DutEventForm.descriptionRequired')),
    pictures: Yup.array()
      .min(1, t('Views.DutEvent.DutEventForm.priorityRequired'))
      .max(
        maxPhotos,
        t('Views.DutEvent.DutEventForm.picturesMax', { max: maxPhotos }),
      )
      .required(t('Views.DutEvent.DutEventForm.picturesRequired')),
    priority: Yup.string().required(
      t('Views.DutEvent.DutEventForm.priorityRequired'),
    ),
  });

  const onSubmit = async (
    values: Values,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<Values>,
  ) => {
    const { pictures, ..._values } = values;
    const imgBlobs = pictures.map((picture) => picture.imgBlob);

    try {
      await api.postEvent({
        authConfig,
        dut: parseInt(dutId!),
        pictures: imgBlobs,
        ..._values,
      });

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }

      enqueueSnackbar(t('Views.DutEvent.DutEventForm.success'), {
        variant: 'success',
      });

      navigate(dutPath);
    } catch (err) {
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: t('General.somethingWentWrong') });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => {
        const addPicture = (captureResult: CaptureResult) =>
          setFieldValue('pictures', [captureResult, ...values.pictures]);

        const removePicture = (index: number) =>
          setFieldValue(
            'pictures',
            values.pictures.filter((_, _index) => _index !== index),
          );

        return (
          <form
            className={clsx(classes.root, className)}
            data-test-id={ViewTestIDs.eventForm.eventFormContainer}
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <Card className={clsx(classes.root, className)} {...props}>
              <CardHeader title={t('Views.DutEvent.DutEventForm.title')} />

              <CardContent>
                <FormControl
                  data-test-id={ViewTestIDs.eventForm.categoryField}
                  error={Boolean(touched.category && errors.category)}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                >
                  <InputLabel id="category-label">
                    {t('Views.DutEvent.DutEventForm.category')}
                  </InputLabel>

                  <Select
                    label={t('Views.DutEvent.DutEventForm.category')}
                    labelId="category-label"
                    name="category"
                    onChange={handleChange}
                    value={values.category}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.category && errors.category && (
                    <FormHelperText>{errors.category}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  data-test-id={ViewTestIDs.eventForm.priorityField}
                  error={Boolean(touched.priority && errors.priority)}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                >
                  <InputLabel id="priority-label">
                    {t('Views.DutEvent.DutEventForm.priority')}
                  </InputLabel>

                  <Select
                    label={t('Views.DutEvent.DutEventForm.priority')}
                    labelId="priority-label"
                    name="priority"
                    onChange={handleChange}
                    value={values.priority}
                  >
                    {priorityLevels.map((priorityLevel) => (
                      <MenuItem key={priorityLevel} value={priorityLevel}>
                        {t('Views.DutEvent.DutEventForm.priorityLevel', {
                          level: priorityLevel,
                        })}
                      </MenuItem>
                    ))}
                  </Select>

                  {touched.priority && errors.priority && (
                    <FormHelperText>{errors.priority}</FormHelperText>
                  )}
                </FormControl>

                <TextField
                  data-test-id={ViewTestIDs.eventForm.descriptionField}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label={t('Views.DutEvent.DutEventForm.description')}
                  margin="normal"
                  maxRows={10}
                  minRows={5}
                  multiline
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  type="text"
                  value={values.description}
                  variant="outlined"
                />

                {errors.submit && (
                  <Box mt={3}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}

                <FormControl
                  data-test-id={ViewTestIDs.eventForm.picturesField}
                  error={Boolean(touched.pictures && errors.pictures)}
                  fullWidth
                  margin="normal"
                  required
                  variant="outlined"
                >
                  <InputLabel
                    className={classes.pictureLabel}
                    id="pictures-label"
                  >
                    {t('Views.DutEvent.DutEventForm.pictures', {
                      amount: values.pictures.length,
                      max: maxPhotos,
                    })}
                  </InputLabel>
                  {!!values.pictures.length && (
                    <Box mt={2} flexWrap="wrap" overflow="hidden">
                      <ImageList
                        data-test-id={
                          ViewTestIDs.eventForm.picturesPreviewContainer
                        }
                        className={classes.gridList}
                        cols={showButtonText ? 3 : 1}
                      >
                        {values.pictures.map((photoPreview, index) => (
                          <ImageListItem
                            data-test-id={ViewTestIDs.eventForm.picturePreview}
                            key={`preview-${index}`}
                          >
                            <img
                              alt={`preview-${index}`}
                              src={photoPreview.dataURL}
                            />

                            <ImageListItemBar
                              actionIcon={
                                <IconButton
                                  className={classes.iconButton}
                                  onClick={() => removePicture(index)}
                                  size="large"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              }
                              className={classes.tileBar}
                              position="top"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                  )}

                  {touched.pictures && errors.pictures && (
                    <FormHelperText>
                      {errors.pictures as string | string[] | undefined}
                    </FormHelperText>
                  )}
                </FormControl>
              </CardContent>

              <CardActions className={classes.buttonWrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={6}>
                    <Button
                      data-test-id={ViewTestIDs.eventForm.abortButton}
                      onClick={() => navigate(dutPath)}
                      startIcon={showButtonText ? <CloseIcon /> : null}
                      variant="contained"
                    >
                      {showButtonText ? t('General.abort') : <CloseIcon />}
                    </Button>
                  </Grid>

                  <Grid item xs={4} md={3}>
                    <Button
                      data-test-id={ViewTestIDs.eventForm.capturePictureButton}
                      disabled={values.pictures.length >= maxPhotos}
                      color="primary"
                      fullWidth
                      onClick={() => setPhotoDialogOpen(true)}
                      startIcon={showButtonText ? <PhotoCameraIcon /> : null}
                      variant="contained"
                    >
                      {showButtonText ? (
                        t('Views.DutEvent.DutEventForm.capturePicture')
                      ) : (
                        <PhotoCameraIcon />
                      )}
                    </Button>
                  </Grid>

                  <Grid item xs={4} md={3}>
                    <Button
                      color="primary"
                      data-test-id={ViewTestIDs.eventForm.submitButton}
                      disabled={isSubmitting}
                      endIcon={
                        isSubmitting ? <CircularProgress size={18} /> : null
                      }
                      fullWidth
                      startIcon={showButtonText ? <SendIcon /> : null}
                      type="submit"
                      variant="contained"
                    >
                      {showButtonText ? (
                        t('Views.DutEvent.DutEventForm.report')
                      ) : (
                        <SendIcon />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>

            {photoDialogOpen && (
              <PhotoDialog
                onCapture={addPicture}
                onClose={() => setPhotoDialogOpen(false)}
                open={photoDialogOpen}
              />
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default EventForm;
