import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { InspectionType } from 'src/types';
import type { GetInspectionTypesProps } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { inspectionKeys } from './keys';

export type UseInspectionTypesReadQueryOptions = UseQueryOptions<
  InspectionType[],
  unknown,
  InspectionType[],
  ReturnType<typeof inspectionKeys.types>
>;

export interface UseInspectionTypesReadProps {
  queryOptions?: UseInspectionTypesReadQueryOptions;
  requestProps?: Omit<GetInspectionTypesProps, 'authConfig'>;
}
/**
 * @description Get the inspection types.
 */
export const useInspectionTypesRead = (
  useInspectionTypesReadProps?: UseInspectionTypesReadProps,
) => {
  const { queryOptions, requestProps } = useInspectionTypesReadProps || {};
  const { axiosRequestConfig: authConfig } = useUserStore();

  return useQuery(
    inspectionKeys.types(),

    () =>
      api.getInspectionTypes({
        authConfig,
        ...requestProps,
      }),

    {
      refetchOnMount: false,
      ...queryOptions,
    },
  );
};
