import type { FetchQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { Dut } from 'src/types';
import type {
  GetDutByIdProps,
  GetDutByTrackingCodeProps,
} from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { dutKeys } from './keys';

export type UseDutReadQueryOptions = UseQueryOptions<
  Dut,
  unknown,
  Dut,
  ReturnType<typeof dutKeys.detail>
>;

export interface UseDutReadProps {
  queryOptions?: UseDutReadQueryOptions;
  requestProps:
    | Omit<GetDutByIdProps, 'authConfig'>
    | Omit<GetDutByTrackingCodeProps, 'authConfig'>;
}
/**
 * @description Get the dut for the provided dut-id or dut-trackingCode.
 */
export const useDutRead = (useDutReadProps: UseDutReadProps) => {
  const { queryOptions, requestProps } = useDutReadProps;
  const { dutId, trackingCode, ...params } = requestProps;
  const { axiosRequestConfig: authConfig } = useUserStore();
  const dutIdOrTrackingCode = { dutId, trackingCode };

  return useQuery(
    dutKeys.detail((dutId || trackingCode) as string | number),

    () =>
      api.getDut({
        authConfig,
        ...(dutIdOrTrackingCode as
          | { dutId: number }
          | { trackingCode: string }),
        ...params,
      }),

    queryOptions,
  );
};

export type FetchDutQueryOptions = FetchQueryOptions<
  Dut,
  unknown,
  Dut,
  ReturnType<typeof dutKeys.detail>
>;

export interface FetchDutProps {
  queryOptions?: FetchDutQueryOptions;
  requestProps:
    | Omit<GetDutByIdProps, 'authConfig'>
    | Omit<GetDutByTrackingCodeProps, 'authConfig'>;
}
/**
 * @description Fetch the dut for the provided dut-id or dut-trackingCode.
 */
export const useFetchDut = () => {
  const { axiosRequestConfig: authConfig } = useUserStore();
  const queryClient = useQueryClient();

  /** Fetch dut from server and return result. */
  const fetchDut = async (fetchDutProps: FetchDutProps) => {
    const { queryOptions, requestProps } = fetchDutProps;
    const { dutId, trackingCode, ...params } = requestProps;
    const dutIdOrTrackingCode = { dutId, trackingCode };
    let dut;

    try {
      dut = await queryClient.fetchQuery(
        dutKeys.detail((dutId || trackingCode) as string | number),

        () =>
          api.getDut({
            authConfig,
            ...(dutIdOrTrackingCode as
              | { dutId: number }
              | { trackingCode: string }),
            ...params,
          }),

        queryOptions,
      );
    } catch {
      dut = getDut(
        (dutIdOrTrackingCode.dutId || dutIdOrTrackingCode.trackingCode)!,
      );
    }

    return dut;
  };

  /** Get dut from cached data. */
  const getDut = (dutIdOrTrackingCode: string | number) =>
    queryClient.getQueryData<Dut>(dutKeys.detail(dutIdOrTrackingCode));

  return { fetchDut, getDut };
};
