import type { FetchQueryOptions, UseQueryOptions } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { Inspection } from 'src/types';
import type { GetInspectionProps } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';

import { inspectionKeys } from './keys';

export type UseInspectionReadQueryOptions = UseQueryOptions<
  Inspection,
  unknown,
  Inspection,
  ReturnType<typeof inspectionKeys.detail>
>;

export interface UseInspectionReadProps {
  queryOptions?: UseInspectionReadQueryOptions;
  requestProps: Omit<GetInspectionProps, 'authConfig'>;
}
/**
 * @description Get the inspection for the provided inspection-id.
 */
export const useInspectionRead = (
  useInspectionReadProps: UseInspectionReadProps,
) => {
  const { queryOptions, requestProps } = useInspectionReadProps;
  const { axiosRequestConfig: authConfig } = useUserStore();

  return useQuery(
    inspectionKeys.detail(requestProps.inspectionId),

    () =>
      api.getInspection({
        authConfig,
        inspectionId: requestProps.inspectionId,
      }),

    {
      staleTime: 0,
      ...queryOptions,
    },
  );
};

export type FetchInspectionQueryOptions = FetchQueryOptions<
  Inspection,
  unknown,
  Inspection,
  ReturnType<typeof inspectionKeys.detail>
>;

export interface FetchInspectionProps {
  queryOptions?: FetchInspectionQueryOptions;
  requestProps: Omit<GetInspectionProps, 'authConfig'>;
}
/**
 * @description Fetch the inspection for the provided inspection-id.
 */
export const useFetchInspection = () => {
  const { axiosRequestConfig: authConfig } = useUserStore();
  const queryClient = useQueryClient();

  /** Fetch inspection from server and return result. */
  const fetchInspection = async (
    fetchInspectionProps: FetchInspectionProps,
  ) => {
    const { queryOptions, requestProps } = fetchInspectionProps;
    let inspection;

    try {
      inspection = await queryClient.fetchQuery(
        inspectionKeys.detail(requestProps.inspectionId),

        () =>
          api.getInspection({
            authConfig,
            inspectionId: requestProps.inspectionId,
          }),

        {
          // Unlike other queries, the inspection detail should be refreshed immediately.
          staleTime: 0,
          ...queryOptions,
        },
      );
    } catch {
      inspection = getInspection(requestProps.inspectionId);
    }

    return inspection;
  };

  /** Get inspection from cached data. */
  const getInspection = (inspectionId: Inspection['id']) =>
    queryClient.getQueryData<Inspection>(inspectionKeys.detail(inspectionId));

  /** Remove inspection from cached data. */
  const removeInspection = (inspectionId: Inspection['id']) =>
    queryClient.removeQueries(inspectionKeys.detail(inspectionId));

  return { fetchInspection, getInspection, removeInspection };
};
