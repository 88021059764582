import type {
  InfiniteData,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import type { Dut, Inspection } from 'src/types';
import type { GetInspectionsProps, PaginatedResponse } from 'src/services/api';
import api from 'src/services/api';
import { useUserStore } from 'src/services/auth/auth';
import { dutKeys } from 'src/services/state/server/Dut';

import { inspectionKeys } from './keys';

export type UseInspectionsReadQueryOptions = UseInfiniteQueryOptions<
  PaginatedResponse<Inspection>,
  unknown,
  PaginatedResponse<Inspection>,
  PaginatedResponse<Inspection>,
  ReturnType<typeof inspectionKeys.list>
>;

export interface UseInspectionsReadProps {
  queryOptions?: UseInspectionsReadQueryOptions;
  requestProps: Omit<GetInspectionsProps, 'authConfig'>;
}

/**
 * @description Get the inspection list for the provided filters.
 */
export const useInspectionsRead = (
  useInspectionsReadProps: UseInspectionsReadProps,
) => {
  const { queryOptions, requestProps } = useInspectionsReadProps;
  const { dutId, location, page, pageSize, type, ...params } = requestProps;
  const { axiosRequestConfig: authConfig } = useUserStore();
  const queryClient = useQueryClient();

  return useInfiniteQuery(
    inspectionKeys.list(requestProps),

    ({ pageParam }) => {
      return api.getInspections({
        authConfig,
        dutId,
        location,
        page: page || pageParam,
        pageSize,
        type,
        ...params,
      });
    },

    {
      getNextPageParam: ({ next }) => {
        const pageParam = next ? new URL(next).searchParams.get('page') : null;
        const page = pageParam ? parseInt(pageParam) : null;

        return page;
      },

      initialData: () => {
        if (!dutId) return undefined;

        const initialData = queryClient.getQueryData<
          InfiniteData<PaginatedResponse<Inspection>>
        >(inspectionKeys.list({}));

        return initialData;
      },

      onSuccess: (infiniteInspectionsData) => {
        // Pre-fill detail inspection queries and detail dut queries after
        // successfully receiving the inspection list.
        infiniteInspectionsData.pages.forEach((page) =>
          page.results.forEach((inspection) => {
            const dutQueryKey = dutKeys.detail(inspection.dut.id);
            const inspectionQueryKey = inspectionKeys.detail(inspection.id);

            queryClient.setQueryData<Dut>(dutQueryKey, inspection.dut);

            queryClient.setQueryData<Inspection>(
              inspectionQueryKey,
              inspection,
            );
          }),
        );
      },

      // Unlike other queries, the inspection list should be refreshed more frequently.
      staleTime: 1000 * 60 * 5, // 5 Minutes

      ...queryOptions,
    },
  );
};
